import React, { useEffect, useState } from "react"
import sanityClient from "../client"
import {Helmet} from "react-helmet-async"
import "./project.css"

export default function Project() {

	const [projectData, setProjectData] = useState(null)

	useEffect(() => {
		sanityClient.fetch(`*[_type == "project"]{
			title,
			date,
			place,
			description,
			projectType,
			link,
			tags
		}`).then((data) => setProjectData(data))
			.catch(console.error) 
	},[])


	if (!projectData) return <div>Loading....</div>
	
// const options = { day: "numeric", month: "numeric", year: "numeric" }

	return (
		<>
			<Helmet>
				<title>Portfolio and Blog | My Portfolio</title>
				<meta name="description" content="Here you will find various projects, tests..." />
				<link rel="canonical" href="/project" />
				
			</Helmet>
			<main className="main-project wallpaper min-h-screen p-12 mt-8 mb-10">
				<section className="project-section container mx-auto">
					<h1 className="h1project">My Portfolio</h1>
					<h2 className="h2project">Showcase of projects</h2>
					<section className="project-section-2 grid md:grid-cols-2 lg:grid-cols-2 gap-8 mt-2">
						{projectData &&
							projectData.map((project, index) => (
								<article className="project-article relative rounded-lg shadow-xl bg-green-100 p-16">
									<h3 className="h3project text-green-800 text-3xl font-bold mb-2 hover:text-gray-500">
										<a
											href={project.link}
											alt={project.title}
											target="_blank"
											rel="noopener noreferrer"
										>
											{project.title}
										</a>
									</h3>
									<div className="project-points flex flex-wrap text-gray-500 text-xs">
										<span>
											<strong className="font-bold">Finished on</strong>:{" "}
											{new Date(project.date).toLocaleDateString()}
										</span>
										<span>
											<strong className="font-bold">Location</strong>:{" "}
											{project.place}
										</span>
										<span>
											<strong className="font-bold">Project Type</strong>:{" "}
											{project.projectType}
										</span>
										<p className="flex flex-wrap my-6 text-lg text-gray-700 leading-relaxed">
											{project.description}
										</p>

										<a
											href={project.link}
											rel="noopener noreferrer"
											target="_blank"
											className="text-green-800 font-bold hover:underline hover:text-green-600"
										>
											View the project{" "}
											<span role="img" aria-label="right-pointer">
												👉
											</span>
										</a>
										<p className="flex flex-wrap mt-4">{project.tags}</p>
									</div>
								</article>
							))}
					</section>
				</section>
			</main>
		</>
	)
}
