import { Link } from "react-router-dom";

export default function NotFound() {
return (
	<div>
		<h1 className="h1nf-post">Oops! You seem to be lost.</h1>
		<p className="h2nf-post">Here are some helpful links:</p>
		<Link to="/">Home</Link>
		<Link to="/post">Blog</Link>
		<Link to="/contact">Contact</Link>
	</div>
)
}