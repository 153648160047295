
import { Routes, Route } from "react-router-dom"
import Home from "./components/Home"
import About from "./components/About"
import SinglePost from "./components/SinglePost"
import Post from "./components/Post"
import Project from "./components/Project"
import Contact from "./components/Contact"
import NavBar from "./components/NavBar"
import BottomNavBar from "./components/BottomNavBar"
import Footer from "./components/Footer"
import Techney from "./components/Techney"
import NotFound from "./components/NotFound"
import Services from "./components/Services"

function App() {
  return (
		<>
			<NavBar />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/services" element={<Services />} />
				<Route path="/techney" element={<Techney />} />
				<Route path="/post" element={<Post />} />
				<Route path="/post/:slug" element={<SinglePost />} />
				<Route path="/project" element={<Project />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
			<Footer />
			<BottomNavBar />
		</>
	)
}

export default App;
