import React, { useState, useEffect } from "react"
import sanityClient from "../client"
import imageUrlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"
import{ Helmet }from "react-helmet-async"
import "./about.css"

const builder = imageUrlBuilder(sanityClient)
function urlFor(source) {
	return builder.image(source)
}

export default function About() {

	const [author, setAuthor] = useState(null)

	useEffect(() => {
		sanityClient.fetch(`*[_type == "author"]{
			name,
			bio,
			"authorImage": image.asset->url
		}`)
			.then((data) => setAuthor(data[0]))
		.catch(console.error)
	},[])
	

if (!author) return <div>Loading....</div>

	return (
		<>
			<Helmet>
				<title>Portfolio and Blog | About</title>
				<meta name="description" content="About Erik Von Schlehenried" />
				<link rel="canonical" href="/about" />
				
			</Helmet>

			<main className="main wallpaper">
				{/* This is for a background image if you want to add one */}
				{/* <img src={" "} className="absolute w-full" alt="" /> */}

				<div className="about-container">
					<section className="section">
						<img
							src={urlFor(author.authorImage).url()}
							className="author"
							alt={author.name}
						/>
						<div className="author-block">
							<h1 className="author-name">
								Hi, I'm {""}
								<span className="">{author.name}</span>
							</h1>
							<div className="about-block">
								<BlockContent
									blocks={author.bio}
									projectId="hmzhydae"
									dataset="production"
								/>
							</div>
						</div>
					</section>
				</div>
			</main>
		</>
	)
}
