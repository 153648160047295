import React from 'react'
import "./services.css"
import { Helmet } from 'react-helmet-async'

export default function Services() {
  return (
    <>
      <Helmet>
				<title>Services and Products</title>
				<meta name="description" content="Services" />
				<link rel="canonical" href="/services" />
				
			</Helmet>
		<div className="deal-container wallpaper">
			  <h1 className="h1service">Services and Products</h1>
			  
			  <div className="table">			
				<stripe-pricing-table
				pricing-table-id="prctbl_1NyZtAAJRLv20Q5HXuOwsGzc"
				publishable-key="pk_live_51JzKA8AJRLv20Q5He7hstQoRCqkLA0ivYshGpQmYPqI5ECqYuQh2A7S0QHaSdcVSLQoHSqNVTKMLra05PZhMgHou00Uh39aIsM"
				  >
				</stripe-pricing-table>
			  </div>
      </div>
    </>
    
	)
}

