import React from 'react';
import { motion } from "framer-motion"
import {Helmet} from "react-helmet-async"
import "./home.css"
import { hotjar } from "react-hotjar"

hotjar.initialize(3240566, 6)

// Identify the user
hotjar.identify("USER_ID", { userProperty: "value" })

// Add an event
hotjar.event("button-click")

// Update SPA state
hotjar.stateChange("/my/page")

// Check if Hotjar has been initialized before calling its methods
if (hotjar.initialized()) {
	hotjar.identify("USER_ID", { userProperty: "value" })
}

export default function Home() {
	return (
		<>
			<Helmet>
				<title>Portfolio and Blog</title>
				<meta
					name="description"
					content="Welcome to the domain of Erik Von Schlehenried"
				/>
				<link rel="canonical" href="/" />
			</Helmet>
			<div className="">
				<div className="service-container wallpaper">
					<div className="service-left">
						<p className="service-intro">Hi, I'm Erik</p>
						<p className="service-name">a Full-Stack Developer...</p>
						<p className="service-desc">Welcome to my domain</p>
					</div>

					<div className="service-right">
						<motion.div
							initial={{ rotate: 45 }}
							whileInView={{ rotate: 0 }}
							viewport={{ margin: '-40px' }}
							transition={{ duration: 2.5, type: 'spring' }}
							className="main-circle"
						>
							<div className="section-circle-finance">
								<div class="centered-finance">Finance</div>
							</div>

							<div className="section-circle-business">
								<div class="centered-business">Business</div>
							</div>

							<div className="section-circle-data">
								<div class="centered-data">Data</div>
							</div>

							<div className="section-circle-tech">
								<div class="centered-tech">Technology</div>
							</div>
						</motion.div>
						<div className="background-circle-green"></div>
						<div className="background-circle-grey"></div>
					</div>
				</div>
			</div>
		</>
	)
}