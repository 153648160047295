import React, {useState, useEffect} from "react"
import sanityClient from "../client"
import { Link } from "react-router-dom"
import {Helmet} from "react-helmet-async"
import "./post.css"





// this is where we pull the data in 

export default function Post() {
// here we will set our state and query language for information
const [postData, setPost] = useState(null)

	useEffect(() => {
		sanityClient
			.fetch(`*[_type == "post"]{
				title,
				slug,
				mainImage{
					asset->{
						_id,
						url
					},
					alt
				}
			}`)
			.then((data) => setPost(data))
		.catch(console.error)
	}, [])
	
if (!postData) return <div>Loading....</div>

	return (
		<>
			<Helmet>
				<title>Portfolio and Blog | My Blog</title>
				<meta name="description" content="Erik's Blog" />
				<link rel="canonical" href="/post" />
				
			</Helmet>
			<main className="main-post wallpaper">
				<section className="section-post container mx-auto">
					<h1 className="h1text-post">My Blog</h1>
					<h2 className="h2text-post">Welcome to my blog</h2>
					<div className="post-block grid md:grid-cols-2 lg:grid-cols-3 gap-8">
						{postData &&
							postData.map((post, index) => (
								<article>
									<Link
										to={"/post/" + post.slug.current}
										key={post.slug.current}
									>
										<span
											className="post-key block h-64 relative rounded shadow leading-snug bg-green-100 border-l-8 border-green-400"
											key={index}
										>
											<img
												src={post.mainImage.asset.url}
												alt={post.mainImage.alt}
												className="post-image w-full h-full rounded-r object-cover absolute"
											/>
											<span className="relative h-full flex justify-end items-end pr-4 pb-4">
												<h3 className="h3text-post text-white text-lg font-blog px-5 py-4 bg-green-700 bg-opacity-75 rounded">
													{post.title}
												</h3>
											</span>
										</span>
									</Link>
								</article>
							))}
					</div>
				</section>
			</main>

			
			
		</>
	)

}
