import React from 'react'
import { Link } from 'react-router-dom'
import { SiAboutdotme } from 'react-icons/si'
import { RiCustomerService2Fill } from 'react-icons/ri'
import { MdContactPage } from 'react-icons/md'
import { FaBlogger } from 'react-icons/fa'
import { BsFileSlides } from 'react-icons/bs'
import { TbRoad } from 'react-icons/tb'
import logo from '../assets/EVS Logo Green Clear.png'
import './bottomnavbar.css'

export default function NavBar() {
	return (
		<>
			<div className="navbarBtm">
				<Link
					to="/"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="bottomLogo ">
						<img src={logo} alt="evs" />
						<p></p>
					</div>
				</Link>
				<Link
					to="about"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="navTabBtm">
						{<SiAboutdotme className="icon1Btm" />}
						<p>About</p>
					</div>
				</Link>
				<Link
					to="services"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="navTabBtm">
						{<RiCustomerService2Fill className="icon6Btm" />}
						<p>Services</p>
					</div>
				</Link>
				<Link
					to="techney"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="navTabBtm">
						{<TbRoad className="icon2Btm" />}
						<p>Techney</p>
					</div>
				</Link>
				<Link
					to="project"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="navTabBtm">
						{<BsFileSlides className="icon3Btm" />}
						<p>Portfolio</p>
					</div>
				</Link>
				<Link
					to="post"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="navTabBtm">
						{<FaBlogger className="icon4Btm" />}
						<p>Blog</p>
					</div>
				</Link>
				<Link
					to="contact"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="navTabBtm">
						{<MdContactPage className="icon5Btm" />}
						<p>Contact</p>
					</div>
				</Link>
			</div>
		</>
	)
}
