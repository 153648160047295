import React from "react"
import { Link } from "react-router-dom"
// import { SocialIcon } from "react-social-icons"
// import { RiHome2Fill } from "react-icons/ri"
import { SiAboutdotme } from "react-icons/si"
import { RiCustomerService2Fill } from "react-icons/ri"
import { MdContactPage } from "react-icons/md"
import { FaBlogger } from "react-icons/fa"
import { BsFileSlides } from "react-icons/bs"
import { TbRoad } from "react-icons/tb"
import logo from "../assets/EVS Logo Green Clear.png"
import "./navbar.css"

export default function NavBar() {
	return (
		<>
			<div className="navbar">
				<Link
					to="/"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="navlogo ">
						<img src={logo} alt="evs" />
						<p></p>
					</div>
				</Link>
				<Link
					to="about"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="NavTab">
						{<SiAboutdotme className="icon2" />}
						<p>About</p>
					</div>
				</Link>
				<Link
					to="services"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="NavTab">
						{<RiCustomerService2Fill className="icon7" />}
						<p>Services</p>
					</div>
				</Link>
				<Link
					to="techney"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="NavTab">
						{<TbRoad className="icon3" />}
						<p>Techney</p>
					</div>
				</Link>
				<Link
					to="project"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="NavTab">
						{<BsFileSlides className="icon4" />}
						<p>Portfolio</p>
					</div>
				</Link>
				<Link
					to="post"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="NavTab">
						{<FaBlogger className="icon5" />}
						<p>Blog</p>
					</div>
				</Link>
				<Link
					to="contact"
					className={({ isActive }) => (isActive ? 'link active' : 'link')}
				>
					<div className="NavTab">
						{<MdContactPage className="icon6" />}
						<p>Contact</p>
					</div>
				</Link>
			</div>
		</>
	)
}
