import React from "react"
import { FaGithub, FaLinkedin } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import "./footer.css"




export default function Footer() {

	

	return (
		<>
			<div className="footer-container">
				<div className="social">
					<ul>
						<li>
							<a
								href="https://github.com/erikvs68"
								target="_blank"
								rel="noreferrer"
							>
								{<FaGithub className="icon" alt="Github" />}
							</a>
						</li>
						<li>
							<a
								href="https://www.x.com/erikvs68"
								target="_blank"
								rel="noreferrer"
							>
								{<FaXTwitter className="icon" alt="X" />}
							</a>
						</li>
						<li>
							<a
								href="https://www.linkedin.com/in/erikvonschlehenried/"
								target="_blank"
								rel="noreferrer"
							>
								{<FaLinkedin className="icon" alt="LinkedIn" />}
							</a>
						</li>
					</ul>
				</div>
				<p className="footer-date">
					{" "}
					&copy; 2023 - Erik Von Schlehenried
				</p>
			</div>
		</>
	)
	
	
}
