import React, { useEffect, useState } from "react"
import sanityClient from "../client"
import {Helmet} from "react-helmet-async"
import "./techney.css"

export default function Project() {
	const [techneyData, settechneyData] = useState(null)

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "techney"]{
			certification,
			date,
			provider,
			description,
			certType,
			link,
			tags
		}`
			)
			.then((data) => settechneyData(data))
			.catch(console.error)
	}, [])

	if (!techneyData) return <div>Loading....</div>

	// const options = { day: "numeric", month: "numeric", year: "numeric" }

	return (
		<>
			<Helmet>
				<title>Portfolio and Blog | My Technology journey</title>
				<meta
					name="description"
					content="My technology journey..."
				/>
				<link rel="canonical" href="/techney" />
				
			</Helmet>
			<main className="main-techney wallpaper">
				<section className="techney-section">
					<h1 className="h1techney">My Techney</h1>
					<h2 className="h2techney">Technology + Journey = Techney</h2>
					<section className="techney-section">
						{techneyData &&
							techneyData.map((techney, index) => (
								<article className="techney-article">
									<h3 className="h3techney">
										<a
											href={techney.link}
											alt={techney.certification}
											target="_blank"
											rel="noopener noreferrer"
										>
											{techney.certification}
										</a>
									</h3>
									<div className="techney-points">
										<span>
											<strong className="date font-bold">Completed</strong>:{" "}
											{new Date(techney.date).toLocaleDateString()}
										</span>
										<span>
											<strong className="provider font-bold">Provider</strong>:{" "}
											{techney.provider}
										</span>
										<span>
											<strong className="cert font-bold">Outcome</strong>:{" "}
											{techney.certType}
										</span>
										<p className="desc">
											{techney.description}
										</p>
										<a
											href={techney.link}
											rel="noopener noreferrer"
											target="_blank"
											className="text-green-800 font-bold hover:underline hover:text-green-600"
										>
											View the techney{" "}
											<span className="img" role="img" aria-label="right-pointer">
												👉
											</span>
										</a>
										<p className="tags">{techney.tags}</p>
									</div>
								</article>
							))}
					</section>
				</section>
			</main>
		</>
	)
}
