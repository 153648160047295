import React from "react"
import { useFormFields, useMailChimpForm } from "use-mailchimp-form"
import {Helmet} from "react-helmet-async"
import "./contact.css"

export default function Contact() {
	const url =
		"https://raxsonic.us17.list-manage.com/subscribe/post?u=a6b1ec174f8469d8852467037&amp;id=9d31594564"
	const { loading, error, success, message, handleSubmit } =
		useMailChimpForm(url)

	const { fields, handleFieldChange } = useFormFields({
		EMAIL: "",
		FNAME: "",
		PHONE: "",
		MESSAGE: "",
	})

	return (
		<>
			<Helmet>
				<title>Portfolio and Blog | Get in touch</title>
				<meta name="description" content="Contact Erik Von Schlehenried" />
				<link rel="canonical" href="/contact" />
				
			</Helmet>
			<div className="contact-container wallpaper">
				<div className="contact-header">
					<div className="span1">Go ahead and </div>
					<div className="span2"> contact me now</div>
				</div>
				<div className="contact">
					<form
						className="contact-form"
						onSubmit={(event) => {
							event.preventDefault()
							handleSubmit(fields)
						}}
					>
						<div className="line">
							<label for="EMAIL" className="label">
								Email<span className="asterisk">*</span>
							</label>
							<input
								className="input"
								id="EMAIL"
								autoFocus
								type="email"
								value={fields.EMAIL}
								onChange={handleFieldChange}
							/>
						</div>
						<div className="line">
							<label for="FNAME" className="label">
								First Name<span className="asterisk">*</span>
							</label>
							<input
								className="input"
								id="FNAME"
								autoFocus
								type="text"
								name="First Name"
								value={fields.FNAME}
								onChange={handleFieldChange}
							/>
						</div>
						<div className="line">
							<label for="PHONE" className="label">
								Phone Number
							</label>
							<input
								className="input"
								id="PHONE"
								autoFocus
								type="text"
								value={fields.PHONE}
								onChange={handleFieldChange}
							/>
						</div>
						<div className="line">
							<label for="MESSAGE" className="label">
								Message<span className="asterisk">*</span>
							</label>
							<input
								className="input"
								id="MESSAGE"
								autoFocus
								type="text"
								value={fields.MESSAGE}
								onChange={handleFieldChange}
							/>
						</div>
						<div className="contact-btn">
							<button>Get in touch</button>
						</div>
					</form>
					<div className="background-circle-contact"></div>
					{loading && "submitting"}
					{error && message}
					{success && message}
				</div>
			</div>
		</>
	)
}
